import { AppLayout } from '@amzn/awsui-components-react-v3';
import { useState } from 'react';

import { Breadcrumbs } from '@/components';
import { FOOTER_SELECTOR } from '@/components/footer/Footer';
import { NAV_BAR_SELECTOR } from '@/components/NavBar';

export const Layout = ({
    useMaxWidth = false,
    navigation,
    breadcrumbItems,
    notifications,
    children,
}) => {
    const [isNavigationOpen, isNavigationOpenSet] = useState(true);
    return (
        <AppLayout
            breadcrumbs={<Breadcrumbs items={breadcrumbItems} />}
            navigationHide={!navigation}
            navigationOpen={isNavigationOpen}
            onNavigationChange={() => isNavigationOpenSet(!isNavigationOpen)}
            navigation={navigation}
            notifications={notifications}
            toolsHide
            headerSelector={`[data-id="${NAV_BAR_SELECTOR}"]`}
            footerSelector={`[data-id="${FOOTER_SELECTOR}"]`}
            maxContentWidth={useMaxWidth ? 980 : 1860}
            content={children}
        />
    );
};
