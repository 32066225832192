import {
    Button,
    Box,
    Container,
    Header,
    Icon,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import moment from 'moment-timezone';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import { LabToggle } from '@/components';
import JamStatusCardLabel from '@/components/jamStatusCardLabel/JamStatusCardLabel';
import detailPageMessages from '@/containers/classPages/ClassDetailPage/ClassDetailPage.messages';
import { paths } from '@/utils';

import messages from './LabCard.messages';

import './LabCard.scss';

const LabCardBase = ({ duration, description, title, statusLabel, children }) => {
    const { formatMessage } = useIntl();

    const labDuration = duration ? (
        <Box
            ariaLabel={formatMessage(detailPageMessages.durationLabel)}
            tagOverride='span'
            padding={{ left: 's' }}
        >
            <Icon name='status-pending' variant='subtle' data-testid='duration-icon' /> {duration}
        </Box>
    ) : null;

    return (
        <Container
            header={
                <>
                    <Header variant='h3' description={description} counter={labDuration}>
                        {title}
                    </Header>
                    {statusLabel}
                </>
            }
        >
            {children}
        </Container>
    );
};

export const LabCard = ({ index, contentId, title, ...rest }) => {
    const { formatMessage } = useIntl();
    const { classroomId } = useParams();
    const history = useHistory();

    const btnOnClick = () => history.push(paths.labPage(classroomId, index + 1));
    return (
        <LabCardBase title={title} {...rest}>
            <SpaceBetween direction='horizontal' alignItems='center' data-styles='card-actions'>
                <Button
                    onClick={btnOnClick}
                    ariaLabel={formatMessage(messages.manageLabsBtnAriaLabel, { title })}
                >
                    {formatMessage(messages.manageLabsBtnLabel)}
                </Button>
                <LabToggle labArn={contentId} classroomId={classroomId} />
            </SpaceBetween>
        </LabCardBase>
    );
};

/**
 * Renders a CardContainer that is specific to Jam. Will render the event status, an event url if present, and lab toggle.
 * @param props.courseTitle - the title of the course, from which the Jam title will be derived
 * @param props.jamIndex - index of this Jam within a list of Jams
 * @param props.jamTraining - the training object for the Jam to render
 */
export const JamCard = ({ courseTitle, jamIndex, jamTraining }) => {
    const { formatMessage, locale } = useIntl();

    const {
        contentId,
        fulfillmentStatus,
        metaData: { eventStatus = '', startsOn = 0, endsOn = 0, eventURL = '' },
    } = jamTraining;

    const title = formatMessage(messages.jamCardLabel, { courseTitle });
    const duration =
        startsOn && endsOn
            ? moment
                  .duration(moment.duration(endsOn - startsOn, 'seconds').asHours(), 'hours')
                  .locale(locale)
                  .humanize()
            : undefined;
    const description = formatMessage(messages.jamCardDescription);
    const index = `jam-${jamIndex}`;
    const props = {
        title,
        contentId,
        duration,
        description,
        index,
    };
    return (
        <LabCardBase
            {...props}
            statusLabel={<JamStatusCardLabel status={eventStatus || fulfillmentStatus} />}
        >
            <Button iconName='external' target='_blank' href={eventURL} disabled={!eventURL}>
                {formatMessage(messages.jamCardFacilitateLabel)}
            </Button>
        </LabCardBase>
    );
};
