import { defineMessages } from 'react-intl.macro';

const headerMessages = defineMessages({
    goToDetail: {
        id: 'header.goToDetail',
        defaultMessage: 'Class Detail',
    },
    settingsDropdown: {
        id: 'header.settingsDropdown',
        defaultMessage: 'Settings',
    },
    accountDropdown: {
        id: 'header.accountDropdown',
        defaultMessage: 'My account',
    },
    signout: {
        id: 'header.signout',
        defaultMessage: 'Sign out',
    },
    awsTAndC: {
        id: 'header.awsTAndC',
        description: 'Name of aws organization',
        defaultMessage: 'AWS Training and Certification',
    },
    currentLocale: {
        id: 'header.currentLocale',
        description: 'Status of current selected locale',
        defaultMessage: 'Locale: {locale}',
    },
    detailPage: {
        id: 'header.detailPage',
        description: 'Detail page for Classroom',
        defaultMessage: 'Classroom Detail Page',
    },
    homepage: {
        id: 'header.homepage',
        description: 'Classroom homepage',
        defaultMessage: 'Homepage',
    },
    emailDisplay: {
        id: 'header.emailDisplay',
        description: 'Text for displaying users email',
        defaultMessage: 'Email: {email}',
    },
    accountIdDisplay: {
        id: 'header.accountIdDisplay',
        description: 'Text for displaying account id',
        defaultMessage: 'Account ID: {userKey}',
    },
    changeProvider: {
        id: 'header.changeProvider',
        description: 'Button text to change provider',
        defaultMessage: 'Change provider',
    },
    copyAccountId: {
        id: 'header.copyAccountId',
        description: 'Label text to copy users account id to clipboard',
        defaultMessage: 'Copy account id to clipboard',
    },
});

export default headerMessages;
