import { TopNavigation } from '@amzn/awsui-components-react-v3';
import { Auth } from 'aws-amplify';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { supportedLocales } from '@/i18n';
import { sendClientLog } from '@/modules/api';
import { useUserInfo, useLocale, copyToClipboard, paths } from '@/utils';

import messages from './index.messages';
import logo from './logo.png';

export const NAV_BAR_SELECTOR = 'top-navigation';

const NavBar = () => {
    const { formatMessage } = useIntl();
    const [{ canChangeLanguage, locale }, localeSet] = useLocale();
    const history = useHistory();
    const { email, userKey } = useUserInfo();

    const localeOptions = useMemo(
        () =>
            supportedLocales.reduce(
                (accumulator, current) => [
                    ...accumulator,
                    {
                        text: current,
                        id: current,
                        disabled: current === locale,
                    },
                ],
                [],
            ),
        [locale],
    );

    const onMenuItemClick = (event) => {
        switch (event.detail.id) {
            case 'signout':
                Auth.signOut();
                break;
            case 'accountId':
                // TODO: add feedback for successful copy
                copyToClipboard({ value: userKey });
                break;
            case 'providers':
                history.push(paths.providersPage);
                break;
            default:
                break;
        }
    };

    const onLanguageChange = (e) => {
        const newLocale = e.detail.id;
        localeSet({ locale: newLocale, userSelected: true });
        sendClientLog({
            type: 'AppClick',
            metrics: { InstructorLanguageSelect: [1, 'Count'] },
            attributes: { locale: newLocale },
        });
    };

    const utilities = [
        {
            type: 'menu-dropdown',
            text: formatMessage(messages.accountDropdown),
            iconName: 'user-profile',
            onItemClick: onMenuItemClick,
            items: [
                {
                    id: 'email',
                    text: formatMessage(messages.emailDisplay, { email }),
                    disabled: true,
                },
                {
                    id: 'accountId',
                    text: formatMessage(messages.accountIdDisplay, { userKey }),
                    iconName: 'copy',
                    iconAlt: formatMessage(messages.copyAccountId),
                },
                { id: 'providers', text: formatMessage(messages.changeProvider) },
                { id: 'signout', text: formatMessage(messages.signout) },
            ],
        },
    ];

    if (canChangeLanguage) {
        utilities.splice(0, 0, {
            type: 'menu-dropdown',
            text: formatMessage(messages.settingsDropdown),
            iconName: 'settings',
            onItemClick: onLanguageChange,
            items: [
                {
                    id: 'locale',
                    text: formatMessage(messages.currentLocale, { locale }),
                    items: localeOptions,
                },
            ],
        });
    }

    return (
        <div style={{ position: 'sticky', top: 0, zIndex: 1002 }} data-testid='nav-bar'>
            <TopNavigation
                data-id={NAV_BAR_SELECTOR}
                identity={{
                    href: paths.root,
                    logo: {
                        src: logo,
                        alt: formatMessage(messages.awsTAndC),
                    },
                }}
                utilities={utilities}
            />
        </div>
    );
};

export default NavBar;
